import React from "react";
import ZavetZdarma from "../templates/ZavetZdarmaTemplate";
import Logo from "../assets/img/sos-logo.png";
import styled from "styled-components";


const TopPanelInner = styled.section`
  border-bottom: 1px solid #DFDFDF;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* border-top: 5px solid red; */
  background: #00adee;


  @media (max-width: 1260px) {
    height: fit-content;
    padding: 20px 0;
  }

  @media (max-width: 580px) {
    padding: 30px 0;
  }


  div {
    @media (max-width: 1260px) {
      display: flex;
      align-items: center;
      justify-content: space-around;
      max-width: calc(100% - 50px);
    }

    @media (max-width: 580px) {
      display: block;
    }
  }


  img {
    height: 100px;
    position: absolute;
    top: 10px;
    left: calc((100% - 1150px)/2);

    @media (max-width: 1260px) {
      position: static;
    }

    @media (max-width: 580px) {
    }
  }


  p {
    font-family: Visuelt-Medium;
    font-size: 18px;
    /* color: #000000; */
    color: #fff;
    text-align: left;
    max-width: 700px;
    line-height: 26px;
    margin-left: 130px;

    @media (max-width: 1260px) {
      text-align: left;
      margin-left: 20px;
    }

    @media (max-width: 580px) {
      margin: 0;
      margin-top: 0;
    }
  }

`;

const TopPanel = () => {
  return (
    <TopPanelInner>
      <div>
        <img src={Logo} alt="SOS dětské vesničky logo" />
        <p>Děkujeme, že jste si vybrali Goodbye na sepsání vaší závěti. Ve spolupráci s organizací SOS dětské vesničky ji můžete vytvořit zdarma (běžně 2 000 Kč).</p>
      </div>
    </TopPanelInner>
  );
}



const Sosvesnicky = () => {

  return (
    <ZavetZdarma
      topPanel={<TopPanel/>} 
      url="sosvesnicky"
      charityTitle="SOS dětské vesničky"
      h1="Vytvořte si vlastní platnou závěť online"
      desc={<p>Jsme hrdým partnerem organizace SOS dětské vesničky. Pro uplatnění své závěti zdarma zadejte slevový kód “<span>sosvesnicky</span>” při platbě.</p>}
    />
  );
}

export default Sosvesnicky;
